<template>
  <div class="ml-n5" style="margin-top:-5px;width: 125px;">
    <tableButtons
      :row="row"
      :currentTable="currentTable"
      :result="result"
      :rowindex="rowindex"
    ></tableButtons>
  </div>
</template>
<script>
import tableButtons from "@/commonComponents/tableButtons.vue";
export default {
  components: { tableButtons },

  props: ["currentTable", "row", "result", "selected", "rowindex"],
};
</script>
