<template>
  <div class="mr-3">
    <template v-if="type=='test'">
      <v-icon title="Test">
        mdi-transfer-right
      </v-icon>
    </template>
    <template v-else-if="type=='stencil'">
      <v-icon title="Stencil">
        mdi-stamper
      </v-icon>
    </template>
  </div>
</template>
<script>

export default {
  name: "testIcons",
  props: ["type"],
}
</script>