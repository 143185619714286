<script setup>
import { ref, onBeforeUnmount, watch } from 'vue';
import { sleep } from "@/js/helper"
import {useStore} from '@/store';
import PhpServiceView from '@/components/basic/php-wrappers/PhpServiceView.vue';
import DynamicTestInfoView from '@/components/dynamic/testInfoView.vue';
import TestInfoReloader from '@/components/dynamic/testInfoReloader.vue';
import StartParametersDialogContainer from "@/components/dynamic/startParametersDialogContainer.vue";
import requests from '@/js/requests';
import {useRoute, onBeforeRouteUpdate} from 'vue-router/composables';
import { debounce } from "lodash-es"

const isRouteUpdating = ref(false);

onBeforeRouteUpdate(async (_, __, next) => {
  isRouteUpdating.value = true;
  next();
  await sleep(500);
  isRouteUpdating.value = false;
});


const store = useStore();
const route = useRoute();
const newResult = ref(null);

const updateStoreTable = (result) => {
  document.title = result.title;
  store.commit('tableChanged', result.table);
};

const unsubscribe = store.subscribe((mutation) => {
  if (mutation.type === 'resultChanged') {
    newResult.value = mutation.payload;
  }
});

const adminInfoRequest = debounce(() => {
  requests.frameworkAxiosRequest({
    method: 'post',
    url: `serve.php?f=testing&f2=testInfo&function=getInfoUpdate`,
    data: {
      n_id: route.params.id,
      n_id_key: route.params.idKey,
      requestType: 'ajax',
      excludeRows: [],
      showInfo: store.state.testInfoAdminInfo,
    },
  })
      .then((response) => {
        store.commit('tableChanged', response.data.result.table);
      })
}, 300);

watch(() => store.state.testInfoAdminInfo, adminInfoRequest);

onBeforeUnmount(() => {
  unsubscribe();
  adminInfoRequest.cancel();
});

</script>

<template>
  <PhpServiceView
    :url="`?f=testing&f2=testInfo&function=getInfo&n_id=${$route.params.id}&n_id_key=${$route.params.idKey}`"
    @result="updateStoreTable"
    #default="{result}"
    :is-loader-shown="isRouteUpdating"
  >
    <DynamicTestInfoView :result="newResult ? newResult : result"/>

    <TestInfoReloader
      v-if="(newResult ? newResult : result).content.includes('testInfoReloader')"
      :result="newResult ? newResult : result"
    />

    <StartParametersDialogContainer
      v-if="result.content.includes('startParametersDialogContainer')"
      :result="result"
    />
  </PhpServiceView>
</template>

